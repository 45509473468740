<template>
  <div>
    <v-app-bar
      color="light-blue accent-2"
      dense
      dark
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Admin</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-menu left bottom transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="settings">
            <v-list-item-action>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="light-blue accent-2"
      dark
      absolute
      temporary
    >
      <v-list-item class="pt-10">
        <v-list-item-avatar>
          <img src="../assets/admin/sombrero-de-graduacion.svg">
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">Teacher Edition</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="light-blue accent-4"
        >
          <v-list-item to="/schoolAdmin">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Teachers</v-list-item-title>
          </v-list-item>
          <v-list-item to="/schoolAdmin/students">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Students</v-list-item-title>
          </v-list-item>
          <v-list-item to="/schoolAdmin/summary" v-if="IsDistrictAdmin">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Summary</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view/>
  </div>
</template>
<script>
import { db,auth } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: false,
    group: null,
    IsDistrictAdmin: false,
  }),
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/";
    },
    settings () {
      console.log('asd');
    },
    async SchoolAd(){
      let uidLogin = this.$store.state.user.uid;
      let doc = await db.collection('SchoolAdmin').doc(uidLogin).get()
      this.user = doc.data();
      if (Object.prototype.hasOwnProperty.call(doc.data(), 'IsDistrictAdmin')) {
        if (doc.data().IsDistrictAdmin === true) {
          this.IsDistrictAdmin = true;
        }
      }
    }
  },
  mounted() {
    let xlsx = document.createElement('script')
    xlsx.setAttribute('src', '/xlsx.full.min.js')
    document.head.appendChild(xlsx)
    let table2excel = document.createElement('script')
    table2excel.setAttribute('src', '/table2excel.js')
    document.head.appendChild(table2excel)
    this.SchoolAd();
  },
}
</script>
