<template>
  <div>
      <div>
        <v-row>
          <iframe class="col-12 video-intro pb-0" height="500" src="https://www.youtube.com/embed/jsUke1XMmNk?controls=0&autoplay=0&modestbranding=1&rel=0" title="Leelee" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </v-row>
        <v-row class="my-0">
          <v-col cols="12" md="6" class="pa-15 my-lg-auto">
            <p class="title-header">{{ $t('homepage.home.title') }}</p>
            <v-btn class="contactus" href="#services">{{ $t('homepage.home.btnMore') }}</v-btn>
          </v-col>
          <v-col cols="12" md="6" class="pb-0" style="text-align: right;">
            <!-- <v-img src="../assets/homepage/imagen-web.png" height="width: 100%;"></v-img> -->
            <img src="../assets/homepage/imagen-web.png" style="width: 100%; height: auto;">
          </v-col>
        </v-row>
      </div>

      <div id="services" class="py-0 center-align all-center bg-leelee bg-img-services py-10 centrado-services">
        <v-container>
        <v-row>
          <v-col>
            <img class="w-50 ma-2" src="../assets/homepage/console.svg">
            <p class="pt-25"><strong v-html="$t('homepage.home.serviceOne')"></strong></p></v-col>
          <v-col>
            <img class="w-50 ma-2" src="../assets/homepage/teaching.svg">
            <p class="pt-25"><strong v-html="$t('homepage.home.serviceTwo')"></strong></p>
          </v-col>
          <v-col>
            <img class="w-50 ma-2" src="../assets/homepage/smartphone.svg">
            <p class="pt-25"><strong v-html="$t('homepage.home.serviceThree')"></strong></p>
          </v-col>
          <v-col>
            <img class="w-50 ma-2" src="../assets/homepage/game-development.svg">
            <p class="pt-25"><strong v-html="$t('homepage.home.serviceFour')"></strong></p>
          </v-col>
        </v-row>
        </v-container>
      </div>

      <div class="all-center" id="aboutus">
        <v-container>
          <v-row class="my-12">
            <v-col class="pa-15 my-lg-auto">
              <h3><strong>{{ $t('homepage.home.missionTitle') }}</strong></h3>
              <p v-html="$t('homepage.home.mission')"></p>
            </v-col>
            <v-col style="text-align: center;"> <img src="../assets/homepage/mission.png"></v-col>
          </v-row>
          <v-row class="my-12">
            <v-col style="text-align: center;"><img src="../assets/homepage/vission.png"></v-col>
            <v-col class="pa-15 my-lg-auto">
              <h3><strong>{{ $t('homepage.home.vissionTitle') }}</strong></h3>
              <p v-html="$t('homepage.home.vission')"></p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-dialog
        transition="dialog-top-transition"
        max-width="900"
        :width="width"
        v-model="dialog"
        
      >
        <div style="position:relative;">
          <v-btn @click="dialog=false" color="blue darken-3" class="white--text" style="position:absolute;z-index: 1; right:0px;">Close
            <v-icon right dark>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        
          <v-card @click="goPricing">
            <v-img src="../assets/homepage/banner.jpg" v-if="horizontal" ></v-img>
            <v-img src="../assets/homepage/banner-vertical.jpg" height="auto" v-else></v-img>

            <!-- <v-toolbar
              color="primary"
              dark
            >Opening from the top</v-toolbar>
            <v-card-text>
              <div class="text-h2 pa-12">Hello world!</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Close</v-btn>
            </v-card-actions> -->
          </v-card>
      </v-dialog>
    <div id="js-cookie-box" class="cookie-box cookie-box--hide">
      We use cookies to customize your browsing experience. By using this website, you agree to our use of cookies. To find out more about the cookies we use, see our <a href="/privacy-policy">Privacy Policy.</a>  <span id="js-cookie-button" class="cookie-button"> OK </span>
    </div>
  </div>
</template>

<script>
// import { functions } from '../plugins/firebase'
export default {
    name: "Home",
    data() {
      return {
        dialog:false,
        horizontal: true,
        width: ''
      }
    },
    methods: {
      goPricing (){
        console.log('asd')
        location.href = '/pricing';
      },
      async verifyVersion(){
        // console.log(process.env.VUE_APP_VERSION)
        // let localVersion =process.env.VUE_APP_VERSION;
        // const getVersion = functions.httpsCallable('getVersion')
        // let response =await getVersion()
        // console.log(response.data)
        // if (response.data != localVersion) {
        //   console.log('diferente')
        //   sessionStorage.clear()
        //   localStorage.clear()
        //   caches.keys().then(keys => {
        //     keys.forEach(key => caches.delete(key))
        //   })
        //   indexedDB.databases().then(dbs => {
        //     dbs.forEach(db => indexedDB.deleteDatabase(db.name))
        //   })
        // }
        var now = new Date();
        let memoria = localStorage.getItem('update');
        now.setHours(0, 0, 0, 0);
        console.log(memoria)
        console.log(now)
        let memoriaDate = new Date(memoria);
        if (now.getTime() > memoriaDate.getTime()) {
          console.log("necesita actualizar")
          sessionStorage.clear()
          localStorage.clear()
          caches.keys().then(keys => {
            keys.forEach(key => caches.delete(key))
          })
          indexedDB.databases().then(dbs => {
            dbs.forEach(db => indexedDB.deleteDatabase(db.name))
          })
          console.log("necesita actualizar")
          indexedDB.databases().then(dbs => { dbs.forEach(db => { if (db.name != "firebaseLocalStorageDb") { indexedDB.deleteDatabase(db.name) } }) })
          var datenow = new Date();
          datenow.setHours(0, 0, 0, 0);
          localStorage.setItem('update', datenow)
          location.reload();
        } else {
          console.log("recargo el mismo dia")
          return;
        }
      }
    },
    created(){
      if(window.innerHeight > window.innerWidth + 200){
        this.horizontal= false
        this.width = window.innerWidth-80;
        //console.log(window.innerWidth)
      }
      
    },
    mounted(){
      this.verifyVersion();
        location.hash = this.$route.hash;
        let altura=150;
        if(window.innerHeight>700)
            altura=200
        var height = window.innerHeight-altura;
        const demoClasses = document.querySelector('.video-intro');
        demoClasses.style.height=height+'px';
        ///setTimeout(() => this.errorMessage = '', 8000);
        //this.dialog=true;
        /////
        const cookieBox = document.getElementById('js-cookie-box');
const cookieButton = document.getElementById('js-cookie-button');

if (!Cookies.get('cookie-box')) {
  
cookieBox.classList.remove('cookie-box--hide');
  
  cookieButton.onclick = function() {
    Cookies.set('cookie-box', true, { expires: 7 });
    cookieBox.classList.add('cookie-box--hide');
  }
}
    },
    watch:{
        'this.$route.hash'(val){
            console.log(val);
            location.hash = this.$route.hash;
        }
    }

}
</script>

<style scoped>
    .bg-img-services{
        background-image: url('../assets/homepage/services.jpg');
        background-position: center;
        background-size: cover;
    }
    
    .contactus{
        background-color: #74cbe6 !important;
        color: white !important;
        font-weight: bold;
    }
    .title-header{

        font-size: 30px;
    }
    .w-50{
      width: 50%!important;
    }
    .centrado-services{
      text-align: center;
    }
    .centrado-services p{
     color: white;
     font-size: 23px;

    }
    #aboutus img{
      width: 80%;
    }
    #aboutus p{
      font-size: 20px;
    }
    #aboutus h3{
      font-size: 35px;
    }

.cookie-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 1rem 2rem;
  background: #fff;
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
}
.cookie-box--hide {
  opacity: 0;
  bottom: -100%;
}

.cookie-button {
  display: inline-block;
  cursor: pointer;
  padding: 0.65rem 0.85rem;
  margin-left: 0.45rem;
  color: #fff;
  font-size: 0.75rem;
  letter-spacing: 1px;
  background-image: linear-gradient(62deg, #21798d 0%, #7cc1d5 100%);
}
</style>