var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"permanent":"","app":"","dark":"","color":"rgba(1,162,165,1)","mini-variant-width":"70","width":"190"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"50","tile":""}},[_c('img',{attrs:{"src":require("../assets/admin/logo-te.png")}})]),_c('v-list-item-content',{staticClass:"py-0"},[_c('p',{staticClass:"subtitle-1 my-auto"},[_vm._v("Teacher Edition")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-chevron-left")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","two-line":""}},[_c('v-list-item',{attrs:{"to":"/schoolTeacher"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1"},[_vm._v("Dashboard")])])],1),(_vm.classroomsEnabled === null)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1 my-auto"},[_vm._v("Charging...")])])],1):(_vm.classroomsEnabled)?_c('v-list-item',{attrs:{"to":"/schoolTeacher/classroom"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("../assets/admin/teacher-home.svg")}})]),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1 my-auto"},[_vm._v("Classroom")])])],1):_c('v-tooltip',{attrs:{"color":"grey darken-3","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("../assets/admin/teacher-home.svg")}})]),_c('v-list-item-content',{},[_c('p',{staticClass:"subtitle-1 my-auto teal--text text--lighten-3"},[_vm._v("Classroom")])])],1)]}}])},[_c('span',[_c('p',{staticClass:"mb-0 mt-2"},[_vm._v("Classroom is only available with the Lee Lee Student Edition purchase.")]),_c('p',{staticClass:"mb-0 mt-2"},[_vm._v("Available in Classroom: ")]),_c('ul',[_c('li',[_vm._v("Game Progress")]),_c('li',[_vm._v("Multiplayer feature")]),_c('li',[_vm._v("Progress Checks")]),_c('li',[_vm._v("Lee Lee At-Home QR Codes")])])])]),_c('v-list-item',{attrs:{"to":"/schoolTeacher/activitieslist"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("../assets/admin/teaching-tools.svg")}})]),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1 my-auto"},[_vm._v("Teaching Tools")])])],1),(_vm.classroomsEnabled === null)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1 my-auto"},[_vm._v("Charging...")])])],1):(_vm.classroomsEnabled)?_c('v-list-item',{attrs:{"to":"/schoolTeacher/statistics"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-align-vertical-bottom")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1"},[_vm._v("Reports")])])],1):_c('v-tooltip',{attrs:{"color":"grey darken-3","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-align-vertical-bottom")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1 teal--text text--lighten-3"},[_vm._v("Reports")])])],1)]}}])},[_c('span',[_c('p',{staticClass:"mb-0 mt-2"},[_vm._v("Reports is only available with the Lee Lee Student Edition purchase.")]),_c('p',{staticClass:"mb-0 mt-2"},[_vm._v("Available in Reports:")]),_c('ul',[_c('li',[_vm._v("Tracking student learning and errors")]),_c('li',[_vm._v("Assesment results")])])])]),_c('v-list-item',{attrs:{"to":"/schoolTeacher/resources"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-file-cog")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1"},[_vm._v("Resources")])])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/schoolTeacher/settings"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1"},[_vm._v("Settings")])])],1),(_vm.isClever)?_c('v-list-item',{attrs:{"to":"/schoolTeacher/help"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-help-circle")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1"},[_vm._v("Help Center")])])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('p',{staticClass:"subtitle-1"},[_vm._v("Logout")])])],1)],1)],1),_c('v-main',{staticStyle:{"height":"100vh"},attrs:{"app":"","fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":"4000","color":"blue-grey"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" se actualizo correctamente ")]),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }