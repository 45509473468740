<template>
  <div>
    <!-- <v-card> -->
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      dark
      color="rgba(1,162,165,1)"
      mini-variant-width="70"
      width="190"
    >
    <v-list two-line>
      <v-list-item >
        <v-avatar size="50" tile class="mr-2">
          <img src="../assets/admin/logo-te.png">
        </v-avatar>
        <v-list-item-content class="py-0">
            <p class="subtitle-1 my-auto">Teacher Edition</p>
          </v-list-item-content>
          
        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon size="40">mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      </v-list>
      <v-divider></v-divider>
      
      <v-list dense two-line>
        <v-list-item to="/schoolTeacher" >
          <v-list-item-icon class="mr-4">
            <v-icon size="30">mdi-home</v-icon>
          </v-list-item-icon>
          
          <v-list-item-content>
            <p class="subtitle-1">Dashboard</p>
          </v-list-item-content>
        </v-list-item>
        <!--<v-list-item to="/schoolTeacher/classroom" v-if="classroomsEnabled && IsRegister !='true'">-->
          <v-list-item v-if="classroomsEnabled === null">
            <v-list-item-icon class="mr-4">
              <!-- Ícono de carga -->
              <v-progress-circular
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>
              <p class="subtitle-1 my-auto" >Charging...</p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/schoolTeacher/classroom" v-else-if="classroomsEnabled">
            <v-list-item-icon class="mr-4">
              <img src="../assets/admin/teacher-home.svg" style="width: 30px; height: 30px;">
            </v-list-item-icon>
            <v-list-item-content>
              <p class="subtitle-1 my-auto" >Classroom</p>
            </v-list-item-content>
          </v-list-item>

          <v-tooltip color="grey darken-3" right v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon class="mr-4">
                  <img src="../assets/admin/teacher-home.svg" style="width: 30px; height: 30px;">
                </v-list-item-icon>
                <v-list-item-content class="">
                  <p class="subtitle-1 my-auto teal--text text--lighten-3" >Classroom</p>
                </v-list-item-content>
              </v-list-item>
            </template>
          <span>
            <p class="mb-0 mt-2">Classroom is only available with the Lee Lee Student Edition purchase.</p>
            <p class="mb-0 mt-2">Available in Classroom: </p>
            <ul>
              <li>Game Progress</li>
              <li>Multiplayer feature</li>
              <li>Progress Checks</li>
              <li>Lee Lee At-Home QR Codes</li>
            </ul>
          </span>
        </v-tooltip>
        
        <v-list-item to="/schoolTeacher/activitieslist">
          <v-list-item-icon class="mr-4">
              <img src="../assets/admin/teaching-tools.svg" style="width: 30px; height: 30px;">
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1 my-auto">Teaching Tools</p>
          </v-list-item-content>
        </v-list-item>
        <!--<v-list-item to="/schoolTeacher/statistics" v-if="classroomsEnabled && IsRegister !='true'">-->
          <v-list-item v-if="classroomsEnabled === null">
            <v-list-item-icon class="mr-4">
              <!-- Ícono de carga -->
              <v-progress-circular
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>
              <p class="subtitle-1 my-auto" >Charging...</p>
            </v-list-item-content>
          </v-list-item>
        <v-list-item to="/schoolTeacher/statistics" v-else-if="classroomsEnabled">  
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-align-vertical-bottom</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Reports</p>
          </v-list-item-content>
        </v-list-item>
        <v-tooltip color="grey darken-3" right v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-4">
                  <v-icon size="30">mdi-align-vertical-bottom</v-icon>
                </v-list-item-icon>

              <v-list-item-content>
                <p class="subtitle-1 teal--text text--lighten-3">Reports</p>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>
            <p class="mb-0 mt-2">Reports is only available with the Lee Lee Student Edition purchase.</p>
            <p class="mb-0 mt-2">Available in Reports:</p>
            <ul>
              <li>Tracking student learning and errors</li>
              <li>Assesment results</li>
            </ul>
          </span>
        </v-tooltip>
        <v-list-item to="/schoolTeacher/resources">
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-file-cog</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Resources</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item to="/schoolTeacher/settings">
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-cog</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Settings</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/schoolTeacher/help" v-if="isClever">
          <v-list-item-icon class="mr-4">
              <v-icon size="30">mdi-help-circle</v-icon>
            </v-list-item-icon>
          <v-list-item-content>
            <p class="subtitle-1">Help Center</p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-action class="mr-4">
              <v-icon size="30">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <p class="subtitle-1">Logout</p>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main app fluid style="height: 100vh;" >
        <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
            se actualizo correctamente
            <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
            </template>
        </v-snackbar>
        <router-view/>
    </v-main>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { auth, db } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: true,
    group: null,
    dialogSettings: false,
    loadingPassword: false,
    password: '',
    snackbar: false,
    textUpdatePassword: '',
    ButtonBakc: false,
    mini: false,
    isClever: false,
    IsRegister: false,
    gradeList: ['Pre-School', 'Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade', '6th Grade', '7th Grade', '8th Grade'],
    classroomsEnabled: null,
    teacherExists: true
  }),
  computed: {
    ...mapState('userTeacher', ['userTeacher'])
  },
  methods: {
    ...mapActions('userTeacher', ['updateUserTeacher']),
    async logout () {        
      await auth().signOut();       
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      localStorage.removeItem('register');
      
      setTimeout(function(){
        location.href = "/login";
      }, 1000);      
    },
    settings () {
      this.dialogSettings = true;
    },
    goRoute (page) {
      // console.log('asd')
      this.$router.push({name: page});
    },
    OpenTe () {
      if (this.$route.name != "activitiesList") {
        location.href = "/schoolTeacher/activitieslist";
      }else{
        this.drawer =false;
      }
    },           
    async getData(){
      db.collection("SchoolTeacher").doc(this.$store.state.user.uid).onSnapshot((teacherData) => {
        console.log(teacherData.data())
        if (teacherData.exists) {
          const teacher=teacherData.data();

          console.log("(((((((((((((((((((((((((((((((((((((((((((((((((((())))))))))))))))))))))))))))))))))))))))))))))))))))");
          const classrooms=Object.keys(teacher.Classrooms);
          console.log(classrooms);
          let position=-1;
          for (const key of classrooms) {              
              position=this.gradeList.indexOf(teacher.Classrooms[key].Grade);
              if (position!==-1)
                  break; 
          }

          //Aqui seleccionamos de que nivel es L1 o L2
          if (position>=0 && position<=3){
            console.log('L1');
          }else if (position>=4 && position<=9){
            console.log('L2');
          }
          
          this.updateUserTeacher(teacher); 

          if (this.IsRegister)
            this.classroomsEnabled=Object.prototype.hasOwnProperty.call(teacher, 'ClassroomsEnabled')?teacher.ClassroomsEnabled:false;
          else
            this.classroomsEnabled=Object.prototype.hasOwnProperty.call(teacher, 'ClassroomsEnabled')?teacher.ClassroomsEnabled:true;          
        }else{
          this.teacherExists=false;
          console.log('El profesor ha sido eliminado.');
        }
      }, (error) => {
        console.log(error);
      });  
    },      
    // ChangePassword () {
    //   if (this.password == '') {
    //     this.textUpdatePassword = 'incorrect password';
    //     setTimeout(() => (this.textUpdatePassword = ''), 3000);
    //     return;
    //   }
    //   this.loadingPassword=true;
    //   var user = auth().currentUser;
    //   // console.log(auth().currentUser)
    //   let aux=this;
    //     user.updatePassword(this.password).then(function() {          
    //       aux.dialogSettings = false;
    //       aux.loadingPassword=false;
    //       aux.snackbar =true;
    //     }).catch(function(error) {
    //       console.log(error)
    //       aux.loadingPassword=false;
    //       aux.textUpdatePassword = error.message;
    //       setTimeout(() => (aux.textUpdatePassword = ''), 3000);
    //     });
    // },
    // async Opentools () {
    //   const token = await auth().currentUser.getIdToken();
    //   console.log(token);
    //   window.location.href = "leelee://game?token="+token;
    //   console.log('asd')
    // }
  },
  created() {
  },
  async mounted() {
    // console.log(this.$store.state.user.uid);
    this.IsRegister = localStorage.getItem('register');
    await this.getData();    

    if (this.$store.state.user.role != "schoolTeacher") {
      this.logout();
    }   
    
    db.collection('TempTokensClever').doc(this.$store.state.user.uid).get().then((doc) => {
        if(doc.exists){
          this.isClever=true;
        }
    });  
  },
  beforeDestroy() {     
  }
}
</script>
<style>
  .icono-lateral img{
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
  .centrar-imagen {
    margin-left: auto;
    margin-right: auto;
  }
  .titulo-card{
    font-size: 1.7em;
    font-weight: 800;
    color: #01a2a5;
  }
</style>
