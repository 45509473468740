<template>
  <div>
    <v-btn color="rgba(2, 130, 124, 1)" class="boton-flotante" dark absolute  fab @click="drawer = true" >
        <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="drawer"
      color="rgba(0, 143, 135, 141)"
      dark
      absolute
      temporary
      width="200"
    >
      <v-list-item class="pt-5">
        <v-avatar size="70" tile class="centrar-imagen">
          <img src="../assets/admin/logo-te.png">
        </v-avatar>
        <!-- <v-list-item-avatar size="150">
         
        </v-list-item-avatar> -->
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">Teacher Edition</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <!--<v-list-item href="/schoolTeacher" >-->
          <v-list-item @click="goToLink('/schoolTeacher')"> 
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="classroomsEnabled === null">
            <v-list-item-icon class="mr-4">
              <!-- Ícono de carga -->
              <v-progress-circular
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>
              <p class="subtitle-1 my-auto" >Charging...</p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goToLink('/schoolTeacher/classroom')"  v-else-if="classroomsEnabled"> 
            <v-list-item-icon>
              <img src="../assets/admin/clase.svg">
            </v-list-item-icon>
            <v-list-item-title>Classroom</v-list-item-title>
          </v-list-item>
          <v-tooltip color="grey darken-3" right v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <img src="../assets/admin/clase.svg">
            </v-list-item-icon>
            <v-list-item-title class="teal--text text--lighten-3">Classroom</v-list-item-title>
        </v-list-item>
          </template>
          <span>
            <p class="mb-0 mt-2">Classroom is only available with the Lee Lee Student Edition purchase.</p>
            <p class="my-0">Available in Classroom: </p>
            <ul>
              <li>Game Progress</li>
              <li>Multiplayer feature</li>
              <li>Progress Checks</li>
              <li>Lee Lee At-Home QR Codes</li>
            </ul>
          </span>
        </v-tooltip>
          <v-list-item @click="OpenTe">
            <div class="icono-lateral">
              <img src="../assets/admin/teaching-tools.svg">
            </div>
            <v-list-item-title>Teaching Tools</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="classroomsEnabled === null">
            <v-list-item-icon class="mr-4">
              <!-- Ícono de carga -->
              <v-progress-circular
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>
              <p class="subtitle-1 my-auto" >Charging...</p>
            </v-list-item-content>
          </v-list-item> 
          <v-list-item @click="goToLink('/schoolTeacher/statistics')" v-else-if="classroomsEnabled">
            <v-list-item-icon>
              <v-icon>mdi-align-vertical-bottom</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item>
          <v-tooltip color="grey darken-3" right v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-align-vertical-bottom</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="teal--text text--lighten-3">Reports</v-list-item-title>
            </v-list-item>
          </template>
          <span>
            <p class="mb-0 mt-2">Reports is only available with the Lee Lee Student Edition purchase.</p>
            <p class="my-0">Available in Reports:</p>
            <ul>
              <li>Tracking student learning and errors</li>
              <li>Assesment results</li>
            </ul>
          </span>
        </v-tooltip>
          <!--<v-list-item to="/schoolTeacher/resources">-->
          <v-list-item @click="goToLink('/schoolTeacher/resources')"> 
            <v-list-item-icon>
              <v-icon>mdi-file-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Resources</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <!--<v-list-item href="/schoolTeacher/settings">-->
        <v-list-item @click="goToLink('/schoolTeacher/settings')"> 
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <!--<v-list-item href="/schoolTeacher/help">-->
        <v-list-item @click="goToLink('/schoolTeacher/help')">  
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Help Center</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon size="30">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <p class="subtitle-1">Logout</p>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-dialog v-model="dialogSettings" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Set up your account
        </v-card-title>
        <v-row class="ma-5">
          <v-col class="ma-4">
            Change your password
          </v-col>
          <v-col>
            <v-text-field outlined label="Password" prepend-icon="mdi-lock" v-model="password" autocomplete="off"></v-text-field>
          </v-col>
          <v-col class="col-3">
            <v-btn class="ma-2" :loading="loadingPassword" :disabled="loadingPassword" color="secondary" @click="ChangePassword">
              Update
            </v-btn>
          </v-col>
        </v-row>
        <v-alert outlined type="warning" prominent border="left" class="ma-4" v-if="textUpdatePassword != ''">
           {{ textUpdatePassword }}
        </v-alert>
      </v-card>
    </v-dialog> -->
    <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
      se actualizo correctamente
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view/>
  </div>
</template>
<script>
import { auth, db } from '../plugins/firebase';
import firebase from "firebase";
export default {
  data: () => ({
    drawer: false,
    group: null,
    dialogSettings: false,
    loadingPassword: false,
    password: '',
    snackbar: false,
    textUpdatePassword: '',    
    IsRegister : false,
    gradeList: ['Pre-School', 'Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade', '6th Grade', '7th Grade', '8th Grade'],
    classroomsEnabled: null,
    teacherExists: true
  }),  
  methods: {
    async logout () {console.log("DESDE LOGOUT...");     
      await auth().signOut();      
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      localStorage.removeItem('register');      
      setTimeout(function(){
        location.href = "/login";
      }, 1000); 
    },
    /* settings () {
      this.dialogSettings = true;
    }, */
    OpenTe () {
      if (this.$route.name != "activitiesList") {
        location.href = "/schoolTeacher/activitieslist";
      }else{
        this.drawer =false;
      }
    },
    async goToLink(link){console.log("DESDE GOTOLINK...");         
      setTimeout(function(){
        location.href = link;
      }, 1000);
    },
    /* async getDataTeacher(item){
      const teacherData=await db.collection("SchoolTeacher").doc(this.$store.state.user.uid).get();
      if (Object.prototype.hasOwnProperty.call(teacherData.data(), item)){
        return teacherData.data()[item];
      }else
        return undefined;
    }, */
    async GetDataTeacher(){
      const teacherData=await db.collection("SchoolTeacher").doc(this.$store.state.user.uid).get();
      if (teacherData.exists) {console.log("Si ESTAMOS AQUI:::::::::::::::");
        const teacher=teacherData.data();

        console.log("(((((((((((((((((((((((((((((((((((((((((((((((((((())))))))))))))))))))))))))))))))))))))))))))))))))))");
        const classrooms=Object.keys(teacher.Classrooms);
        console.log(classrooms);
        let position=-1;
        for (const key of classrooms) {              
            position=this.gradeList.indexOf(teacher.Classrooms[key].Grade);
            if (position!==-1)
                break; 
        }

        //Aqui seleccionamos de que nivel es L1 o L2
        if (position>=0 && position<=3){
          console.log('L1');
        }else if (position>=4 && position<=9){
          console.log('L2');
        }
        
        //this.updateUserTeacher(teacher); 

        if (this.IsRegister)
          this.classroomsEnabled=Object.prototype.hasOwnProperty.call(teacher, 'ClassroomsEnabled')?teacher.ClassroomsEnabled:false;
        else
          this.classroomsEnabled=Object.prototype.hasOwnProperty.call(teacher, 'ClassroomsEnabled')?teacher.ClassroomsEnabled:true;          
      } else {
        console.log('No está disponible');
      }
    },    
  },
  created() {       
  },
  async mounted() {
    this.IsRegister = localStorage.getItem('register');
    await this.GetDataTeacher();   
    if (this.$store.state.user.role != "schoolTeacher") {
      this.logout();
    }

    // this.classroomsEnabled=await this.getDataTeacher('ClassroomsEnabled');
    // this.classroomsEnabled=(this.classroomsEnabled==undefined?true:this.classroomsEnabled);
    
    
  },
  beforeDestroy() {
  }
}
</script>
<style>
  .icono-lateral img{
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
  .centrar-imagen {
    margin-left: auto;
    margin-right: auto;
  }
  .boton-flotante {
    width: 45px !important;
    height: 45px !important;
  }
  @media only screen and (max-width: 1200px) {
    .boton-flotante {
      width: 40px !important;
      height: 40px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    .boton-flotante {
      width: 35px !important;
      height: 35px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .boton-flotante {
      width: 30px !important;
      height: 30px !important;
    }
  }
</style>