const state = {
  userTeacher: {}
};

const mutations = {
  SET_USER_TEACHER(state, teacher) {
    state.userTeacher = teacher;
  }
};

const actions = {
  updateUserTeacher({ commit }, teacher) {
    commit('SET_USER_TEACHER', teacher);
  }
};

const getters = {
  getUserTeacher: (state) => state.userTeacher
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};