<template>
  <div>
  <header class="main-header">     
    <div class="header-bottom">
        <div class="container">
          <div class="clearfix">
            <div class="logo-box pull-left">
              <a href="https://claseleelee.com/" class="logo-link">
                <img class="mt-2 logo_" style="width: 125px;" src="../assets/homepage/logo_leelee_solo.png" alt="Lee Lee">
              </a>
            </div>
            <div style="bottom: 43px;" class="nav-outer pull-right clearfix">
              <div style="top: 0;" class="menu-area">
                <nav class="main-menu navbar-expand-lg">
                  <div class="navbar-header">
                    <!-- Toggle Button -->      
                    <button type="button" class="navbar-toggle">
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </div>
                  <div class="navbar-collapse collapse clearfix">
                    <ul class="navigation clearfix">
                      <li><a href="https://claseleelee.com/en/" class="text-dark Home" id="Home">Home</a></li>
                      <li><a href="https://claseleelee.com/en/products" class="text-dark Products" id="Products">Products</a></li>
                      <!-- Other navigation items... -->
                      <li><a href="https://claseleelee.com/en/sales" class="text-dark Sales" id="Sales">Sales</a></li>
                      <li><a class="text-dark Contactus" style="cursor: pointer;" id="Contactus" href="https://claseleelee.com/en/support">Contact us</a>
                        <ul>
                          <li><a href="https://claseleelee.com/en/support" class="Support" id="Support">Support</a></li>
                          <li><a href="https://claseleelee.com/en/faq" class="Faq" id="Faq">Faq</a></li>
                        </ul>
                      </li>
                      <li><a class="text-dark Resources" style="cursor: pointer;" id="Resources" href="https://claseleelee.com/en/technical-information">Resources</a>
                        <ul>
                          <li><a href="https://claseleelee.com/en/technical-information" class="Compatibility" id="Compatibility">Compatibility</a></li>
                        </ul>
                      </li> 
                      <li><a href="https://claseleelee.com/en/experiences" class="text-dark Experiences" id="Experiences">Experiences</a></li>
                      <li>
                        <a id="dropdownMenuButton" class="btn text-dark">
                          <i style="color: black;" class="fas fa-globe pr-2"></i><span class="Lang"></span>
                        </a>
                        <!--<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <button class="dropdown-item ES">ES</button>
                          <button class="dropdown-item EN">EN</button>
                        </div>-->
                      </li>
                      <li><a href="https://apps.claseleelee.com/" class="text-dark rounded-pill" style="background-color: #3ba2b7;color: white !important;">Login</a></li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>          
        </div>
    </div>
  </header>
  <div>
        <transition>
          <router-view/>
        </transition>
      </div>
  </div>
</template>
<script>
export default {
}
</script>
<style>
/** main-header **/

.main-header{
  position:relative;
  left:0px;
  top:0px;
  background: rgba(255, 255, 255, 0.8);
  z-index:999;
  width:100%;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;overflow: hidden;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}

.main-header .header-top{
  position: relative;
  display: block;
 /* background: #253b70;*/
  /*background-image: url(../images/background/1.jpg);*/
  background-position: top;
  background-size: cover;
  width: 100%;
}

.main-header .header-top .social-list li{
  position: relative;
  display: inline-block;
  margin-right: 20px;
  list-style: none;
}

.main-header .header-top .social-list li:last-child{
  margin-right: 0px;
}

.main-header .header-top .social-list li a{
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 26px;
  color: #fff;
}

.main-header .header-top .social-list li a:hover,
.main-header .header-top .header-info li a:hover{
  color: #ff7162;
}

.main-header .header-top .header-info li{
  position: relative;
  display: inline-block;
  margin-right: 32px;
  padding: 0px 0px 0px 22px;
}

.main-header .header-top .header-info li:last-child{
  margin-right: 0px;
}

.main-header .header-top .header-info li:before{
  position: absolute;
  content: '';
  border: 1px dashed #59688d;
  height: 20px;
  width: 1px;
  top: 5px;
  right: -18px;
}

.main-header .header-top .header-info li:last-child:before{
  display: none;
}

.main-header .header-top .header-info li i{
  position: absolute;
  left: 0px;
  top: 7px;
  font-size: 14px;
  color: white;
}

.main-header .header-top .header-info li a{
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #d3d8e2;
}

.main-header .outer-content li{
  position: relative;
  display: inline-block;
}

.main-header .outer-content li.header-flyout-searchbar i{
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  background: #fbb419;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 7px;
  transition: all 500ms ease;
}

.main-header .outer-content li a{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  background: #43b3d9;
  text-align: center;
  border-radius: 50%;
}

.main-header .outer-content li a:hover,
.main-header .outer-content li.header-flyout-searchbar i:hover{
  background: #ff7162;
}

.main-header .outer-box{
  margin-top: 32px;
}

.pull-left{
  float: left;
}

.main-header .logo-box .logo{
  position: relative;
  display: inline-block;
  padding: 26px 0px;
}

.main-header .logo-box .logo:before{
  position: absolute;
  content: '';
  /*background: url(../images/icons/logo-shap.png);*/
  width: 216px;
  height: 57px;
  left: -20px;
  bottom: -57px;
  background-repeat: no-repeat;
}
  
  
  /** main-menu **/
  
  .main-header .menu-area,
  .main-header .outer-box{
    position: relative;
    float: right;
  }
  
  .main-header .social-style-one{
    margin-top: 22px;
  }
  
  .main-header .menu-area{
    margin-right: 22px;
  }
  
  .main-menu{
    position: relative;
    display: block;
  }
  
  .main-menu .navbar-collapse{
    padding:0px;  
  }
  
  .main-menu .navigation > li{
    position:relative;
    float:left;
    margin: 0px 6px;
    list-style: none;
  }
  
  .main-menu .navigation > li:first-child{
    margin-left: 0px;
  }
  
  .main-menu .navigation > li > a{
    position:relative;
    display:block;
    font-size:18px;
    color:#343a40;
    text-decoration: none;
    line-height:26px;
    /*font-weight:500;*/
    opacity:1;
    text-transform:capitalize;
    padding: 10px;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .main-menu .navigation > li.current > a,
  .main-menu .navigation > li:hover > a{
    color: #212230;
  }
  
  .main-menu .navigation{
    position: relative;
  }
  
  .main-menu .navigation > li > ul{
    position:absolute;
    left:0px;
    top: 100%;
    width:250px;
    z-index:100;
    display:none;
    background-color: white;
   /* background-image: url(../images/background/TABLA-MENU-PRUEBA3.jpg);*/
    border-top: 3px solid #73b9d1;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
  }
  
  .main-menu .navigation > li > ul > li{
    position:relative;
    width:100%;
    padding: 7px 30px;
    border-bottom: 1px solid #e5e5e5;
    transition: all 500ms ease;
  }
  
  .main-menu .navigation > li > ul > li:last-child{
    border-bottom:none; 
  }
  
  .main-menu .navigation > li > ul > li > a{
    position:relative;
    display:block;
   font-family: 'Ubuntu', sans-serif;
    padding:6px 0px;
    line-height:24px;
    font-size:15px;
    color: #3b5998;
    font-weight: 700;
    text-align:left;
    text-transform:capitalize;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
  }
  
  .main-menu .navigation > li > ul > li:hover{
    background: #73b9d1;
    border-bottom: 1px solid #73b9d1;
  }
  
  .main-menu .navigation > li > ul > li:hover a{
    color: #ffffff;
    padding-left: 10px;
  }
  
  .main-menu .navigation > li > ul > li:last-child:hover{
    border-bottom: none;
  }
  
  .main-menu .navigation > li.dropdown:hover > ul{
    visibility:visible;
    opacity:1;
  }
  
  .main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
    position:absolute;
    right: 15px;
    top: 8px;
    font-size:18px;
    border: 1px solid #ffffff;
    color:#ffffff;
    width: 35px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor:pointer;
    z-index:5;
    display: none;
  }
  
  .navbar-header {
    display: none;
  }
  
  /** sub-menu **/
  
  .main-menu .navigation > li > ul > li > ul{
    position:absolute;
    left:100%;
    top:0%;
    width:230px;
    z-index:100;
    display:none;
    padding: 0px 0px;
    background-color: #ffffff;
    border-top: 3px solid #ff7162;
    -webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
  }
  
  .main-menu .navigation li > ul > li.dropdown:hover > ul {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
  
  .main-menu .navigation > li > ul > li > ul > li{
    position:relative;
    width:100%;
    margin:0px;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .main-menu .navigation > li > ul > li > ul > li:last-child{
    border-bottom:none; 
  }
  
  .main-menu .navigation > li > ul > li > ul > li > a{
    position:relative;
    display:block;
    padding:10px 25px !important;
    line-height:24px;
    font-weight:700;
   font-family: 'Ubuntu', sans-serif;
    font-size:15px;
    text-transform:capitalize;
    color: #2b3c6b !important;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
  }
  
  .main-menu .navigation > li > ul > li > ul > li:hover > a{
    color: #ffffff !important;
    background:#ff7162;
    padding-left: 35px !important;
  }
  
  .main-menu .navigation > li > ul > li.dropdown > a:after {
    position: absolute;
    content: "\f105";
    font-size: 16px;
    color: #222;
    top: 6px;
    right: -12px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    cursor: pointer;
    transition: all 500ms ease;
  }
  
  .main-menu .navigation > li > ul > li.dropdown:hover > a:after{
    color: #ffffff;
  }
</style>
