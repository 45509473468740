import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '../plugins/firebase';
import JWTDecode from 'jwt-decode'
import jwt from 'jsonwebtoken'
import userTeacher from './modules/userTeacher'; 

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      email: localStorage.getItem('email'),
      uid: localStorage.getItem('uid'),
      role: localStorage.getItem('role'),
      token: localStorage.getItem('token')
    },
    initialTime: localStorage.getItem('initialTime'),
    savedTime: localStorage.getItem('savedTime'),
    activeWeb: localStorage.getItem('activeWeb')
  },
  mutations: {
    SET_USER: (state, account) => {
      state.user = account;
      localStorage.setItem('email', account.email)
      localStorage.setItem('uid', account.uid)
      localStorage.setItem('role', account.role)
      localStorage.setItem('token', account.token)
    },//Actualizamos el tiempo en linea
    setInitialTime(state, time) {      
      state.initialTime = localStorage.setItem('initialTime', time);      
    },
    setSavedTime(state, time) {
      state.savedTime = localStorage.setItem('savedTime', time);
    },
    setActiveWeb(state, activeWeb=false){
      state.activeWeb = localStorage.setItem('activeWeb', activeWeb);
    }
  },
  actions: {
    async login ({ commit }, account) {
      try {
        await auth().signInWithEmailAndPassword(account.email, account.password);
  
        const token = await auth().currentUser.getIdToken();
        console.log(token);
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        if (decoded.superAdmin) {
          await auth().signOut()
          localStorage.removeItem('email');
          localStorage.removeItem('uid');
          localStorage.removeItem('role');
          localStorage.removeItem('token');
            window.location.href = "https://app.claseleelee.com/login";
          role = 'superAdmin'
        }
        if (decoded.schoolAdmin) {
          await auth().signOut()
          localStorage.removeItem('email');
          localStorage.removeItem('uid');
          localStorage.removeItem('role');
          localStorage.removeItem('token');
            window.location.href = "https://app.claseleelee.com/login";
          role = 'schoolAdmin'
        }
        if (decoded.schoolTeacher) {
          role = 'schoolTeacher'
        }
        if (decoded.parent) {
          role = 'parent'
          // let token2 = jwt.sign({ uid: auth().currentUser.uid }, 'secret');
          // console.log(token2);  
          // document.cookie = `token=${token2}; domain=claseleelee.com; secure`;
          // const LoginCookie = functions.httpsCallable('LoginCookie')
          // await LoginCookie({idToken:token})
        }
        if (decoded.individualTeacher) {
          role = 'individualTeacher'
        }
        if (decoded.student) {
          role = 'student'
        }
        // Cookie.set('access_token', token)
        const { email, uid } = auth().currentUser;
        console.log(auth().currentUser)
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    async loginGoogle ({ commit }, emailTemp) {
      // const token = await auth().currentUser.getIdToken();
      // const decoded = JWTDecode(token);
      try {  
        const token = await auth().currentUser.getIdToken();
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        if (decoded.superAdmin) {
           await auth().signOut()
          localStorage.removeItem('email');
          localStorage.removeItem('uid');
          localStorage.removeItem('role');
          localStorage.removeItem('token');
            window.location.href = "https://app.claseleelee.com/login";
          role = 'superAdmin'
        }
        if (decoded.schoolAdmin) {
          await auth().signOut()
          localStorage.removeItem('email');
          localStorage.removeItem('uid');
          localStorage.removeItem('role');
          localStorage.removeItem('token');
            window.location.href = "https://app.claseleelee.com/login";
          role = 'schoolAdmin'
        }
        if (decoded.schoolTeacher) {
          role = 'schoolTeacher'
        }
        if (decoded.parent) {
          role = 'parent'
        }
        if (decoded.individualTeacher) {
          role = 'individualTeacher'
        }
        if (decoded.student) {
          role = 'student'
        }
        // Cookie.set('access_token', token)
        const { email, uid } = auth().currentUser;
        if (uid != '' && email == '')
          email = emailTemp;
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    async loginClever ({ commit }, nuevo) {
      try {  
        const token = await auth().currentUser.getIdToken(nuevo);
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        let register =null;
        if (decoded.schoolTeacher) {
          role = 'schoolTeacher'
        }
        if (decoded.student) {
          role = 'student'
        }
        if (decoded.registerTeacher && decoded.registerTeacher===true) {
          localStorage.setItem('register', true)
        }
        // Cookie.set('access_token', token)
        const { email, uid } = auth().currentUser;
  
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    async loginClassLink ({ commit }, nuevo) {
      try {  
        const token = await auth().currentUser.getIdToken(nuevo);
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        // if (decoded.schoolTeacher) {
        //   role = 'schoolTeacher'
        // }
        // if (decoded.student) {
        //   role = 'student'
        // }
        role = 'schoolTeacher'
        const { email, uid } = auth().currentUser;
  
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    async loginClassLinkStudent ({ commit }, nuevo) {
      try {  
        const token = await auth().currentUser.getIdToken(nuevo);
        const decoded = JWTDecode(token);
        console.log(decoded)
        let role = null;
        // if (decoded.schoolTeacher) {
        //   role = 'schoolTeacher'
        // }
        // if (decoded.student) {
        //   role = 'student'
        // }
        role = 'student'
        const { email, uid } = auth().currentUser;
  
        commit('SET_USER', { email, uid, role, token });
      } catch (error) {
        console.log(error)
        throw error;
      }
    }
  },
  modules: {
    userTeacher
  }
})
