<template>
  <div>    
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      dark
      color="#4491FD"
      mini-variant-width="70"
      width="190"      
    >
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <img src="../assets/admin/1LogoLeelee.png" class="imagen-info" style="width: 90%;" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item center>
          <v-list-item-content class="py-0 text-center">
            <p class="subtitle-1 my-auto">{{manager}}</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      
      <v-list dense two-line>
        <v-list-item :to="{ path: '/licenses-admin' }" exact>
          <v-list-item-icon class="mr-4">
            <v-icon size="30">mdi-home</v-icon>
          </v-list-item-icon>
          
          <v-list-item-content>
            <p class="subtitle-1">Home</p>
          </v-list-item-content>
        </v-list-item>        
        
        <v-list-item :to="{ path: '/licenses-admin/licenses' }">
          <v-list-item-icon class="mr-4">
            <v-icon size="30">mdi-account-key</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Licenses</p>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item :to="{ path: '/licenses-admin/student-usage' }">
          <v-list-item-icon class="mr-4">
            <v-icon size="30">mdi-chart-bar</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <p class="subtitle-1">Reports</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item @click="logout">
          <v-list-item-action class="mr-4">
            <v-icon size="30">mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <p class="subtitle-1">Logout</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main app fluid style="height: 100vh;" >
      <v-snackbar top v-model="snackbar" timeout="4000" color="blue-grey">
        se actualizo correctamente
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
import { auth, db } from '../plugins/firebase'
export default {
  data: () => ({
    drawer: false,
    mini: false,
    snackbar: false,
    manager: ""
  }),
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/";
    },
    async schoolAdmin(){
      let uidLogin = this.$store.state.user.uid;
      let doc = await db.collection('SchoolAdmin').doc(uidLogin).get()
      this.user = doc.data();
      this.manager=`${this.user.Name} ${this.user.LastName}`;
      if (Object.prototype.hasOwnProperty.call(doc.data(), 'IsDistrictAdmin')) {
        if (doc.data().IsDistrictAdmin === true) {
          this.IsDistrictAdmin = true;
        }
      }
    }
  },
  created() {  
    this.schoolAdmin();
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>

<style>   
  .v-list .v-list-item--active {
    color: inherit;
    /*background-color: #a1c8fe;*/
  }
  .theme--dark.v-navigation-drawer .v-divider {    
    width: 90%;
    border: 1px solid #a1c8fe;
    margin-left: auto;
    margin-right: auto;
  }
  .v-data-table-header{
    background-color: #a1c8fe;
    font-weight: bold;
  }
</style>